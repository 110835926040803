@import "../../../baseTheme/settings";

.product-tile-pd {
    // font-family: $product-font;

    .product-tile-image {
        margin-bottom: 16px;
        border-radius: 12px;
        overflow: hidden;

        .product-tile-component-figure {
            width: 100%;
        }

        .product-tile-component-image {
            width: 100%;
        }
    }

    // DO NOT display quickview for now since we have not yet implemented the feature.
    // .product-tile-image:hover .quick-shop {
    //     margin: -2.875em 1em 0.5em;
    //     @include media-breakpoint-up(lg) {
    //         display: block;
    //     }
    // }

    .product-tile-body {
        .product-ratings {
            margin-bottom: 8px;

            .ratings {
                color: get-brand-color(sageGreen);
            }

            .rating-number {
                color: #A4A4A4;
                font-size: 12px;
                letter-spacing: -2%;
            }
        }

        .product-price {
            font-size: 12px;
            letter-spacing: -2%;

            span {
                font-size: 16px;
                letter-spacing: -2%;
            }
        }

        .product-name-link {
            font-size: 16px;
            letter-spacing: -2%;
            margin-bottom: 8px;

            a {
                color: var(--color-primary);
                @include primaryFont(700);
            }
        }
    }
}
